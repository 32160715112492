import { useSpring, animated } from '@react-spring/web'

// Components
import Button from '@/components/ui/button'

// Assets
import CheckIcon from '@/assets/svg/circle-check.svg'

const SmartFilter = ({
  children,
  isActive,
  className,
  activeClassName,
  ...rest
}) => {
  activeClassName = activeClassName || 'text-primary border-primary'

  const styles = useSpring({
    opacity: isActive ? 1 : 0,
    width: isActive ? 15 : 0,
    marginRight: isActive ? 8 : 0,
    x: isActive ? 0 : -8,
    config: { mass: 0.75, tension: 250, friction: 14 },
  })

  return (
    <Button
      {...rest}
      className={`h-35 rounded p-10 flex items-center border text-14 leading-none my-2 motion-safe:transition-all motion-safe:duration-100 ${
        isActive ? activeClassName : 'border-dark-10 text-dark-60'
      } ${className}`}
      unstyled
      aria-pressed={isActive}
    >
      <animated.div
        className="h-15 rounded-full flex items-center justify-center"
        style={styles}
      >
        <CheckIcon width={15} height={15} />
      </animated.div>
      {children}
    </Button>
  )
}

export { SmartFilter }
