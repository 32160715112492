import api from '@/services/api'

import Bali from '@/assets/svg/destinations/bali.svg'
import NewYork from '@/assets/svg/destinations/new-york.svg'
import Bangkok from '@/assets/svg/destinations/bangkok.svg'
import Cancun from '@/assets/svg/destinations/cancun.svg'
import { useRouter } from 'next/router'
import useAnalytics from '@/hooks/analytics'
import useTranslation from '@/hooks/translation'

const DestinationCard = ({ origin, destination, cityName, icon }) => {
  const router = useRouter()
  const { track } = useAnalytics()

  return (
    <button
      onClick={() => {
        track('inspiration_search_launched', {
          origin: origin,
          destination: destination,
        })
        api.search
          .createSearch({
            available_partnerships: [],
            ignore_special_offers: true,
            type: 'bundle',
            slices: [
              {
                origin: origin,
                destination: destination,
                departure_date: '2023-06-01',
              },
              {
                origin: destination,
                destination: origin,
                departure_date: '2023-06-15',
              },
            ],
            passengers: [{ type: 'adult' }],
            cabin_class: 'economy',
          })
          .then(({ data }) => {
            if (data?.id) {
              const query = { id: data.id }
              router.push({
                pathname: '/search/[id]',
                query,
              })
            }
          })
      }}
      className="border border-dark-20 rounded-10 shadow col-span-3 flex flex-row p-20 items-center bg-white hover:shadow-xl transform motion-safe:transition-all motion-safe:duration-200 cursor-pointer"
    >
      <div className="mr-10">
        <div className="w-[58px] h-[58px] relative">{icon}</div>
      </div>

      <div className="inline-block align-middle text-left">
        <div className="block text-dark font-bold text-16">{cityName}</div>
        <div className=" text-dark-60 text-14">
          {origin} → {destination}
        </div>
      </div>
    </button>
  )
}

export default function Inspiration({ search }) {
  const { t } = useTranslation()

  return (
    <div className="px-20 py-10">
      <div className="font-bold text-dark mb-10">
        {t('components.formDesktop.inspiration.title')}
      </div>

      <div className="grid grid-cols-12 gap-15">
        <DestinationCard
          origin={'PAR'}
          destination={'NYC'}
          cityName={'New York, USA'}
          search={search}
          icon={
            <NewYork className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          }
        />
        <DestinationCard
          origin={'PAR'}
          destination={'DPS'}
          cityName={'Bali, Indonésie'}
          search={search}
          icon={
            <Bali className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          }
        />
        <DestinationCard
          origin={'PAR'}
          destination={'BKK'}
          cityName={'Bangkok, Thailande'}
          search={search}
          icon={
            <Bangkok className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          }
        />
        <DestinationCard
          origin={'PAR'}
          destination={'CAN'}
          cityName={'Cancun, Mexique'}
          search={search}
          icon={
            <Cancun className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          }
        />
      </div>
    </div>
  )
}
